/* @import url("https://fonts.googleapis.com/css?family=Poppins"); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap'); */
body {
	font-family: "Inter", sans-serif;
	scrollbar-width: none;
	-ms-overflow-style: none;
}

* {
	margin: 0;
	padding: 0;
}
