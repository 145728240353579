.carousel-page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    row-gap: 30px;
    overflow: hidden;
    margin-top: 40px;
  }
  
  .car-image-container {
    height: 100%;
    width: 100%;
    display: flex;
    gap: 20px;
    flex-shrink: none;
    border-radius: 20px;
  }
  
  .car-image {
    height: 100%;
    border-radius: 20px;
  }
  
  @keyframes scrollForward {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  @keyframes scrollBackward {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  
  .car-image-wrapper {
    display: flex;
    width: 100vw;
    gap: 20px;
  }
  
  .car-image-wrapper:hover {
    border-radius: 35px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
  .car-image {
    height: 300px;
  }
  
  .car-image:hover {
    /* scale: 1.2; */
    transition: 0.2s linear;
  }
  
  .car-image img {
    height: 100%;
    width: 100%;
  }
  
  .car-image-wrapper:nth-child(1) {
    animation: scrollForward 50s linear infinite;
  }
  
  .car-image-wrapper:nth-child(2) {
    animation: scrollBackward 50s linear infinite;
  }
  
  