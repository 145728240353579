.my-cart-container {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	height: 160px;
	width: 80%;
	text-align: center;
	box-shadow: 0px 4px 4px #0076b647;
	padding: 5px;
	background-color: rgba(253, 253, 253, 0.1);
}
.counter2 {
	height: 30px;
	border: 1px solid #000;
	border-radius: 10px;
}
.btn1 {
	width: 20px;
	height: 20px;
	border-radius: 50%;
}
.btn2 {
	width: 20px;
	height: 20px;
	border-radius: 50%;
}

.counter2 {
	color: #8c0710;
	border: 2px solid #8c0710;
}
