.btn-add-more {
	background-color: #fff;
	color: #8c0710;
	border-radius: 30px;
	border: 2px solid #8c0710;
	width: 150px;
	height: 50px;
	font-size: 20px;
	text-align: center;
	font-weight: 900;
	margin-left: 60px;
}
.add-more-prod-button {
	padding-left: 150px;
}
.items-in-the-cart-heading {
	margin-left: 25px;
}
