.bill-container {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	height: 400px;
	width: 90%;
	text-align: center;
	box-shadow: 0px 4px 4px #0076b647;
	background-color: #f0ebeb4d;
	border-radius: 20px;
}
.line6-container {
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	width: 650px;
	opacity: 0.9;
}
.btn-pay-checkout {
	background: linear-gradient(180deg, #ffd980 0%, #8c0710 100%);
	color: #fff;
	border-radius: 20px;
	width: 240px;
	height: 60px;
	font-size: 20px;
	text-align: center;
	font-weight: 900;
}
