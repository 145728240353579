.Login_modal {
	background-color: rgba(255, 255, 255, 0.45);
	-webkit-backdrop-filter: blur(8px);
	z-index: 999;
	backdrop-filter: blur(8px);
	position: absolute;
	border-radius: 20px;
	width: 40vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.modal_header {
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	font-size: 3vh;
	justify-self: center;
	align-self: center;
}
.btn-login {
	background: #ffd980;
	background: -webkit-linear-gradient(to bottom, #ffd980, #8c0710);
	background: linear-gradient(to bottom, #ffd980, #8c0710);
	color: #fff;
	border-radius: 12px;
	/* top: 180px; */
	width: 70px;
	height: 40px;
	font-size: 15px;
	text-align: center;
	font-weight: 600;
}
